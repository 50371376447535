.mainDiv {
	text-align: left;
	max-width: 1000px;
	margin-left: 20px;
	margin-right: 20px;
	margin-bottom: 50px;
}
.Verified {
	color: #c45500 !important;
	font-weight: bold !important;
}
.title {
	font-weight: bold !important;
	margin-bottom: 10px;
}
.subTitle {
	color: #565959 !important;
}
.name {
	/* align-items: center; */
	top: 22%;
	position: absolute;
	margin-left: 10px;
}
.nameContainer {
	position: relative;
}
