.V_Sector {
	align-items: center;
}
.reviewContainer {
	border-top: 1px solid #e3e3e3;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
	text-align: start;
}
.starsImg {
	width: 15%;
}
