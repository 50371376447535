.StickyFooterContainer {
	background-color: white;
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 85px;
}
.hidden {
	display: none;
}

.outer {
	display: table;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
}

.middle {
	display: table-cell;
	vertical-align: middle;
}

.spanTextStickyFooter {
	font-size: 1.3rem;
}
.spaceAtStickyFooter {
	width: 100px;
}
