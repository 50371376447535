.PromotionalSectionContainer {
	background-color: #f94c49;
	display: flex;
}
.h1Promotional {
	color: #fff;
	font-weight: bold;
	margin-top: 30px;
	margin-bottom: 0px;
}
.PromotionalDataSection {
	text-align: start;
	margin-left: 30px;
}

.P_Promotional {
	color: #fff;
}

.promotionalh3SemiTitle {
	font-weight: bold;
	font-size: 1.5rem;
}
