.designeKore {
	background-color: #f94c49;
	border: none;
	color: white;
	font-weight: bold;
	border-radius: 0.4rem;
	/* margin: 10px 10px 10px 10px; */
	font-size: 1.125rem;
	width: 100%;
	max-width: 260px;
	height: 50px;
}
.buttonDataContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.spanDataOfButton {
	margin-top: 4px;
}
