@media (min-width: 601px) {
	.wrapperDivKore {
		flex-direction: row-reverse;
	}
	.containerDiv {
		border-right: 1px solid;
	}
	.divContainer {
		display: flex;
	}
	.ActivityImg {
		width: 100%;
	}
	.activityTextContainer {
		width: 100%;
	}
	.containerActivityDiv {
		width: 30%;
		min-width: 180px;
	}
	.activityBoxesDivContainer {
		display: flex;
		flex-direction: row;
		max-width: 90%;
		justify-content: center;
		gap: 10px;
		margin: 50px;
	}
	.WatchFeatureItemContainer {
		margin-left: 100px;
		margin-right: 100px;
		display: flex;
		align-items: center;
	}
	.WatchFeatureItemContainerRight {
		flex-direction: row-reverse;
	}
	.WatchFeatureItemContainerLeft {
		flex-direction: row;
	}
	.WatchFeatureItemImg {
		height: 50vw;
		max-height: 550px;
	}
	.PromotionalSectionContainer {
		flex-direction: row-reverse;
		margin-top: 200px;
	}
	.ImgPromotional {
		margin-top: 150px;
	}
	.PromotionalDataSection {
		/* height: 10vw; */
	}
	.h1Promotional {
		font-size: 2.5rem;
	}
	.ImgPromotional {
		width: 50%;
	}
	.GuaranteeSectionBorderContainer {
		flex-direction: column;
	}
	.GuaranteeSectionBolderP {
		padding-left: 200px;
		left: 0;
		text-align: start;
	}
	.GuaranteeSectionFirst {
		text-align: start;
	}
	.GuaranteeSectionSecond {
		padding-left: 200px;
		text-align: start;
		margin-bottom: 20px;
	}

	.GuaranteeImg {
		position: absolute;
		width: 180px;
		padding-right: 300px;
	}
}
/* if screen under 600px */
@media (max-width: 600px) {
	.wrapperDivKore {
		flex-direction: column;
	}
	.containerDiv {
		border-right: 0px solid;
	}
	.divContainer {
		display: block;
	}
	.ActivityImg {
		width: 100%;
	}
	.containerActivityDiv {
		width: 100%;
	}
	.WatchFeatureItemContainer {
		padding: 20px 20px 20px 20px;
	}
	.WatchFeatureItemImg {
		width: 80%;
	}
	.PromotionalSectionContainer {
		flex-direction: column;
		align-items: center;
		margin-top: 0;
		padding-bottom: 20px;
	}
	.h1Promotional {
		font-size: 2.5rem;
	}
	.ImgPromotional {
		width: 60%;
	}
	.ImgPromotional {
		margin-top: 15px;
	}
	.PromotionalDataSection {
		width: 70%;
	}
}

/* @media (min-width: 835px) {
	.h1Promotional {
		font-size: 3.5rem;
	}
	.ImgPromotional {
		width: 60%;
	}
} */
/* under 836 */
/* @media (max-width: 836px) {
	.h1Promotional {
		font-size: 2.5rem;
	}
	.ImgPromotional {
		width: 50%;
		margin-top: 260px;
	}
}
@media (max-width: 336px) {
	.h1Promotional {
		font-size: 2.5rem;
	}
	.ImgPromotional {
		width: 50%;
		margin-top: 260px;
	}
} */
