.HeaderContainer {
	background-color: #000000;
	width: 100%;
	/* padding: 15px; */
	padding-top: 10px;
	padding-bottom: 10px;
}
.HeaderDataContainer {
	display: flex;
	justify-content: space-around;
}

.HeaderLogo {
	height: 65.8125px;
	width: 180.4px;
	max-width: 194px;
	padding-left: 10px;
}

.HeaderData {
	color: #8f8f8f;
	padding-top: 0px;
	font-size: 1.2rem;
}
