.divQuestion {
	width: 100%;
}
.QuestionContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.answerData {
	text-align: start;
	width: 100%;
	animation: fade-in 0.5s ease-in-out;
}

@keyframes fade-in {
	0% {
		opacity: 0;

		transform: translateY(-30px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.buttonQuestion {
	background-color: white;
	border: none;
	font-size: 1.2rem;
	width: 100%;
	padding: 0px 0px 0px 0px;
}
.PlusImgQuestion {
	height: 1.2rem;

	width: 1.2rem;
}

.rotateToX {
	animation: rotate-keyframes 1s ease-in-out;
	transform: rotate(45deg);
}
.rotateToPlus {
	animation: rotate-keyframes-to-plus 1s ease-in-out;
	transform: rotate(360deg);
}

@keyframes rotate-keyframes {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(45deg);
	}
}
@keyframes rotate-keyframes-to-plus {
	from {
		transform: rotate(45deg);
	}

	to {
		transform: rotate(180deg);
	}
}
