.designe {
	background-color: #fa9e3d;
	border: none;
	color: white;
	font-weight: bold;
	padding: 12px;
	border-radius: 0.4rem;
	margin: 10px 10px 20px 10px;
	/* margin-bottom: 100px; */
	font-size: 1.125rem;
	width: 90%;
	max-width: 250px;
	text-decoration: none;
}
.designe:hover {
	background-color: #01ba5a;
	transition: background-color 0.8s ease;
}
