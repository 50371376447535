.iframeWrapper {
	position: relative;
	overflow: hidden;
}
.iframe {
	position: absolute;
	border: 0;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	max-width: 1200px;
}
