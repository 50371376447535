.designeB {
	background-color: #000;
	border: none;
	color: white;
	font-weight: bold;
	padding: 12px;
	border-radius: 0.4rem;
	margin: 10px 10px 0px 0px;
	font-size: 1.125rem;
	width: 90%;
	max-width: 250px;
}
