@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 1000px) {
	.DivOfImages {
		flex-direction: column;
		align-items: center;
	}
	.gallery {
		width: 80%;
		margin-top: 20px;
	}
	.iframe {
		height: 100%;
	}
	.iframeWrapper {
		width: 100%;
		padding-top: 56.25%;
	}
}
@media (min-width: 1000px) {
	.DivOfImages {
		flex-direction: row;
	}
	.gallery {
		width: 30%;
	}
	.iframe {
		height: 600px;
	}
	.iframeWrapper {
		width: 1065px;
		padding-top: 675px;
	}
}

@media (min-width: 600px) {
	.wrapperDiv {
		flex-direction: row;
	}
}
@media (max-width: 600px) {
	.wrapperDiv {
		flex-direction: column-reverse;
	}
}

@media (min-width: 601px) {
	.wrapperDivKore {
		flex-direction: row-reverse;
	}
	.containerDiv {
		border-right: 1px solid;
	}
	.divContainer {
		display: flex;
	}
	.ActivityImg {
		width: 100%;
	}
	.activityTextContainer {
		width: 100%;
	}
	.containerActivityDiv {
		width: 30%;
		min-width: 180px;
	}
	.activityBoxesDivContainer {
		display: flex;
		flex-direction: row;
		max-width: 90%;
		justify-content: center;
		grid-gap: 10px;
		gap: 10px;
		margin: 50px;
	}
	.WatchFeatureItemContainer {
		margin-left: 100px;
		margin-right: 100px;
		display: flex;
		align-items: center;
	}
	.WatchFeatureItemContainerRight {
		flex-direction: row-reverse;
	}
	.WatchFeatureItemContainerLeft {
		flex-direction: row;
	}
	.WatchFeatureItemImg {
		height: 50vw;
		max-height: 550px;
	}
	.PromotionalSectionContainer {
		flex-direction: row-reverse;
		margin-top: 200px;
	}
	.ImgPromotional {
		margin-top: 150px;
	}
	.PromotionalDataSection {
		/* height: 10vw; */
	}
	.h1Promotional {
		font-size: 2.5rem;
	}
	.ImgPromotional {
		width: 50%;
	}
	.GuaranteeSectionBorderContainer {
		flex-direction: column;
	}
	.GuaranteeSectionBolderP {
		padding-left: 200px;
		left: 0;
		text-align: start;
	}
	.GuaranteeSectionFirst {
		text-align: start;
	}
	.GuaranteeSectionSecond {
		padding-left: 200px;
		text-align: start;
		margin-bottom: 20px;
	}

	.GuaranteeImg {
		position: absolute;
		width: 180px;
		padding-right: 300px;
	}
}
/* if screen under 600px */
@media (max-width: 600px) {
	.wrapperDivKore {
		flex-direction: column;
	}
	.containerDiv {
		border-right: 0px solid;
	}
	.divContainer {
		display: block;
	}
	.ActivityImg {
		width: 100%;
	}
	.containerActivityDiv {
		width: 100%;
	}
	.WatchFeatureItemContainer {
		padding: 20px 20px 20px 20px;
	}
	.WatchFeatureItemImg {
		width: 80%;
	}
	.PromotionalSectionContainer {
		flex-direction: column;
		align-items: center;
		margin-top: 0;
		padding-bottom: 20px;
	}
	.h1Promotional {
		font-size: 2.5rem;
	}
	.ImgPromotional {
		width: 60%;
	}
	.ImgPromotional {
		margin-top: 15px;
	}
	.PromotionalDataSection {
		width: 70%;
	}
}

/* @media (min-width: 835px) {
	.h1Promotional {
		font-size: 3.5rem;
	}
	.ImgPromotional {
		width: 60%;
	}
} */
/* under 836 */
/* @media (max-width: 836px) {
	.h1Promotional {
		font-size: 2.5rem;
	}
	.ImgPromotional {
		width: 50%;
		margin-top: 260px;
	}
}
@media (max-width: 336px) {
	.h1Promotional {
		font-size: 2.5rem;
	}
	.ImgPromotional {
		width: 50%;
		margin-top: 260px;
	}
} */

.App {
	text-align: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	font-family: 'Source Sans Pro', sans-serif;
	height: 100%;
}
.homePageDataContainer {
	max-width: 1100px;
}

html {
	font-size: 16;
}

.designe {
	background-color: #fa9e3d;
	border: none;
	color: white;
	font-weight: bold;
	padding: 12px;
	border-radius: 0.4rem;
	margin: 10px 10px 20px 10px;
	/* margin-bottom: 100px; */
	font-size: 1.125rem;
	width: 90%;
	max-width: 250px;
	text-decoration: none;
}
.designe:hover {
	background-color: #01ba5a;
	transition: background-color 0.8s ease;
}

.reviewImage {
	max-width: '900px';
}
.gallery {
	border-radius: 6px;
}
.bestSellerImg {
	width: 30%;
	margin-top: 20px;
}
.MainProductImg {
	width: 100%;
}
.descriptionImage {
	max-width: 1050px;
	width: 100%;
}
.UserReviewImg {
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
}
.Reviews5Stars {
	width: 7.5rem;
	height: 1.5rem;
}

.wrapperDiv {
	display: flex;
	padding-top: 10px;
}

.priceWrapper {
	text-align: left;
	padding: 20px;
}
.price {
	color: #b93936;
	font-size: 20px;
	font-weight: bolder;
}
.listWrapper {
	list-style-type: disc;
	text-align: left;
	padding: 20px;
}
.H2Reviews {
	color: #3136d2;
	font-weight: bold;
	text-decoration: none;
}

.sloganWrapper {
	font-style: italic;
	font-family: 'Source Sans Pro';
	font-size: 30px;
	font-weight: 400;
}

.iframeWrapper {
	position: relative;
	overflow: hidden;
}
.iframe {
	position: absolute;
	border: 0;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	max-width: 1200px;
}

.DivOfImages {
	display: flex;
	justify-content: space-around;
	width: 100%;
	margin-top: 20px;
	max-width: 1200px;
}

.mainDiv {
	text-align: left;
	max-width: 1000px;
	margin-left: 20px;
	margin-right: 20px;
	margin-bottom: 50px;
}
.Verified {
	color: #c45500 !important;
	font-weight: bold !important;
}
.title {
	font-weight: bold !important;
	margin-bottom: 10px;
}
.subTitle {
	color: #565959 !important;
}
.name {
	/* align-items: center; */
	top: 22%;
	position: absolute;
	margin-left: 10px;
}
.nameContainer {
	position: relative;
}

.App {
	text-align: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	font-family: 'Source Sans Pro', sans-serif;
	height: 100%;
	width: 100%;
}
.homePageDataContainer {
	max-width: 1100px;
	width: 100%;
}
.GapAtBottom {
	height: 85px;
}

.HeaderContainer {
	background-color: #000000;
	width: 100%;
	/* padding: 15px; */
	padding-top: 10px;
	padding-bottom: 10px;
}
.HeaderDataContainer {
	display: flex;
	justify-content: space-around;
}

.HeaderLogo {
	height: 65.8125px;
	width: 180.4px;
	max-width: 194px;
	padding-left: 10px;
}

.HeaderData {
	color: #8f8f8f;
	padding-top: 0px;
	font-size: 1.2rem;
}

.wrapperDivKore {
	display: flex;
	padding-top: 10px;
}

.priceWrapper {
	text-align: left;
	padding: 20px;
}
.price {
	color: #b93936;
	font-size: 20px;
	font-weight: bolder;
}
.listWrapper {
	list-style-type: disc;
	text-align: left;
	padding: 20px;
}
.fiveStartsImage {
}
.H2Reviews {
	color: #3136d2;
	font-weight: bold;
	text-decoration: none;
}
.fiveStarsText {
	color: #7c7c7c;
}

.designeKore {
	background-color: #f94c49;
	border: none;
	color: white;
	font-weight: bold;
	border-radius: 0.4rem;
	/* margin: 10px 10px 10px 10px; */
	font-size: 1.125rem;
	width: 100%;
	max-width: 260px;
	height: 50px;
}
.buttonDataContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.spanDataOfButton {
	margin-top: 4px;
}

.containerDiv {
	padding: 20px 20px 20px 20px;
}
.containerDivLast {
	padding: 20px 20px 20px 20px;
}

/* .divContainer {
	display: flex;
} */

.containerActivityDiv {
	align-items: center;
	padding-bottom: 30px;
}

.WatchFeatureItemContainer {
}
.WatchFeatureItemContainerRight {
}
.WatchFeatureItemContainerLeft {
}
.WatchFeatureItemImg {
}

.V_Sector {
	align-items: center;
}
.reviewContainer {
	border-top: 1px solid #e3e3e3;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
	text-align: start;
}
.starsImg {
	width: 15%;
}

.PromotionalSectionContainer {
	background-color: #f94c49;
	display: flex;
}
.h1Promotional {
	color: #fff;
	font-weight: bold;
	margin-top: 30px;
	margin-bottom: 0px;
}
.PromotionalDataSection {
	text-align: start;
	margin-left: 30px;
}

.P_Promotional {
	color: #fff;
}

.promotionalh3SemiTitle {
	font-weight: bold;
	font-size: 1.5rem;
}

.designeB {
	background-color: #000;
	border: none;
	color: white;
	font-weight: bold;
	padding: 12px;
	border-radius: 0.4rem;
	margin: 10px 10px 0px 0px;
	font-size: 1.125rem;
	width: 90%;
	max-width: 250px;
}

.GuaranteeSectionContainer {
	background-color: #262626;
	color: #fff;
}
.GuaranteeSectionBorderContainer {
	display: flex;
	margin-left: auto;
	margin-right: auto;
	width: 80%;
	flex-direction: column;
}
.GuaranteeSectionFirst {
}
.GuaranteeSectionSecond {
}
.GuaranteeSectionBolderP {
	font-weight: bolder;
	font-size: 1.5rem;
}
.GuaranteeImg {
	margin-top: 10px;
}

.QuestionsContainer {
	width: 80%;
	margin-left: auto;
	margin-right: auto;
}
.QuestionsTitle {
	font-weight: bold;
	font-size: 1.8rem;
}

.divQuestion {
	width: 100%;
}
.QuestionContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.answerData {
	text-align: start;
	width: 100%;
	animation: fade-in 0.5s ease-in-out;
}

@keyframes fade-in {
	0% {
		opacity: 0;

		transform: translateY(-30px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.buttonQuestion {
	background-color: white;
	border: none;
	font-size: 1.2rem;
	width: 100%;
	padding: 0px 0px 0px 0px;
}
.PlusImgQuestion {
	height: 1.2rem;

	width: 1.2rem;
}

.rotateToX {
	animation: rotate-keyframes 1s ease-in-out;
	transform: rotate(45deg);
}
.rotateToPlus {
	animation: rotate-keyframes-to-plus 1s ease-in-out;
	transform: rotate(360deg);
}

@keyframes rotate-keyframes {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(45deg);
	}
}
@keyframes rotate-keyframes-to-plus {
	from {
		transform: rotate(45deg);
	}

	to {
		transform: rotate(180deg);
	}
}

.FooterContainer {
	background-color: #222;
	color: #9b9b9b;
}
.linkAtFooter {
	text-decoration: none;
	color: #9b9b9b;
}

.linkAtFooter {
	text-decoration: none;
	color: #9b9b9b;
}

.StickyFooterContainer {
	background-color: white;
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 85px;
}
.hidden {
	display: none;
}

.outer {
	display: table;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
}

.middle {
	display: table-cell;
	vertical-align: middle;
}

.spanTextStickyFooter {
	font-size: 1.3rem;
}
.spaceAtStickyFooter {
	width: 100px;
}

