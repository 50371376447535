.wrapperDivKore {
	display: flex;
	padding-top: 10px;
}

.priceWrapper {
	text-align: left;
	padding: 20px;
}
.price {
	color: #b93936;
	font-size: 20px;
	font-weight: bolder;
}
.listWrapper {
	list-style-type: disc;
	text-align: left;
	padding: 20px;
}
.fiveStartsImage {
}
.H2Reviews {
	color: #3136d2;
	font-weight: bold;
	text-decoration: none;
}
.fiveStarsText {
	color: #7c7c7c;
}
