.reviewImage {
	max-width: '900px';
}
.gallery {
	border-radius: 6px;
}
.bestSellerImg {
	width: 30%;
	margin-top: 20px;
}
.MainProductImg {
	width: 100%;
}
.descriptionImage {
	max-width: 1050px;
	width: 100%;
}
.UserReviewImg {
	width: 2rem;
	height: 2rem;
	border-radius: 50%;
}
.Reviews5Stars {
	width: 7.5rem;
	height: 1.5rem;
}
