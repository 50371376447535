.GuaranteeSectionContainer {
	background-color: #262626;
	color: #fff;
}
.GuaranteeSectionBorderContainer {
	display: flex;
	margin-left: auto;
	margin-right: auto;
	width: 80%;
	flex-direction: column;
}
.GuaranteeSectionFirst {
}
.GuaranteeSectionSecond {
}
.GuaranteeSectionBolderP {
	font-weight: bolder;
	font-size: 1.5rem;
}
.GuaranteeImg {
	margin-top: 10px;
}
