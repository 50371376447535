@media (max-width: 1000px) {
	.DivOfImages {
		flex-direction: column;
		align-items: center;
	}
	.gallery {
		width: 80%;
		margin-top: 20px;
	}
	.iframe {
		height: 100%;
	}
	.iframeWrapper {
		width: 100%;
		padding-top: 56.25%;
	}
}
@media (min-width: 1000px) {
	.DivOfImages {
		flex-direction: row;
	}
	.gallery {
		width: 30%;
	}
	.iframe {
		height: 600px;
	}
	.iframeWrapper {
		width: 1065px;
		padding-top: 675px;
	}
}

@media (min-width: 600px) {
	.wrapperDiv {
		flex-direction: row;
	}
}
@media (max-width: 600px) {
	.wrapperDiv {
		flex-direction: column-reverse;
	}
}
