@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
.App {
	text-align: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	font-family: 'Source Sans Pro', sans-serif;
	height: 100%;
	width: 100%;
}
.homePageDataContainer {
	max-width: 1100px;
	width: 100%;
}
.GapAtBottom {
	height: 85px;
}
